<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{ $t("message.database.tablePurge") }}</b-card-title>
      <b-card-sub-title>{{
        $t("message.database.purgeLabel")
      }}</b-card-sub-title>
    </b-card-body>
    <b-card>
      <div>
        <div>
          <b-row>
            <div class="col-md-12">
              <div class="mb-1">
                <v-select v-model="tableSelected" :options="tableOptions" />
              </div>
            </div>
          </b-row>
        </div>
      </div>
      <br />
      <div class="text-right">
        <b-button
          v-b-modal.table-purge-modal
          variant="primary"
          :disabled="!tableSelected"
          >
            {{ $t("message.database.purgeBtn") }}
            
          </b-button
        >
      </div>
    </b-card>

    <b-card-body>
      <b-card-title>
         {{ $t("message.database.purgeTitle") }}
      </b-card-title>
      <b-card-sub-title
        >         {{ $t("message.database.purgeSubTitle") }}
</b-card-sub-title
      >
    </b-card-body>
    <b-card>
      <div>
        <div>
          <b-row>
            <div class="col-md-12">
              <div class="mb-1">
                <v-select
                  v-model="organizationSelected"
                  :reduce="(val) => val.value"
                  :options="organizationOptions"
                />
              </div>
            </div>
          </b-row>
        </div>
      </div>
      <br />
      <div class="text-right">
        <b-button
          v-b-modal.organization-purge-modal
          variant="primary"
          :disabled="!organizationSelected"
          >{{ $t("message.database.purgeBtn") }}</b-button
        >
      </div>
    </b-card>
    <b-modal
      id="table-purge-modal"
      modal-class="modal-danger"
      centered
      title="Confirmação do purge"
      hide-footer
    >
      <b-card-text>
        Tem certeza que deseja realizar o <b>purge</b> ? (Essa alteração não
        pode ser desfeita)
      </b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          @click="createPurge()"
        >
          {{ $t("message.database.purgeBtn") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="organization-purge-modal"
      modal-class="modal-danger"
      centered
      title="Confirmação do purge"
      hide-footer
    >
      <b-card-text>
        Tem certeza que deseja realizar o <b>purge</b> ? (Essa alteração não
        pode ser desfeita)
      </b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          @click="createOrganizationPurge()"
        >
          {{ $t("message.database.purgeBtn") }}
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BButton,
  BFormFile,
  BFormTextarea,
  BRow,
  BFormSelect,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BFormFile,
    BFormTextarea,
    BRow,
    BFormSelect,
    vSelect,
    BCardText,
  },
  data() {
    return {
      tableSelected: "",
      organizationSelected: "",

      //Select
      tableOptions: [],
      organizationOptions: [],

      userData: store.state.user.userData,
    };
  },

  async created() {
    await axios
      .get(`${URL_API}table/list`, { headers: getHeader(this.userData) })
      .then((response) => {
        this.tableOptions = response.data;
      });

    var organizationList = [];
    var organizationId = [];
    await axios
      .get(`${URL_API}organization/all`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].company;
          const id = response.data[i].id;
          organizationList.push(element);
          organizationId.push(id);
          this.organizationOptions.push({
            label: organizationList[i],
            value: organizationId[i],
          });
        }
      });
  },

  methods: {
    createPurge() {
      if (this.tableSelected != "") {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();
        today = dd + "/" + mm + "/" + yyyy;
        var newName =
          this.tableSelected + "_purge_" + dd + "_" + mm + "_" + yyyy;

        this.$root.$emit("bv::hide::modal", "table-purge-modal");

        axios({
          method: "put",
          url: `${URL_API}table/${newName}/${this.tableSelected}`,
          headers: getHeader(this.userData),
        }).then(() => {
          this.tableSelected = "";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.purge"),
            },
          });

          this.tableSelected = "";
          this.tableOptions = [];

          axios
            .get(`${URL_API}table/list`, { headers: getHeader(this.userData) })
            .then((response) => {
              this.tableOptions = response.data;
            });
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("Error"),
            icon: "XIcon",
            variant: "danger",
            text: this.$i18n.t("message.toast.tableNotSelected"),
          },
        });
      }
    },

    createOrganizationPurge() {
      this.$root.$emit("bv::hide::modal", "organization-purge-modal");

      axios({
        method: "delete",
        url: `${URL_API}purge/${this.organizationSelected}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.tableSelected = "";
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.purge"),
          },
        });

        this.organizationOptions = [];
        this.organizationSelected = "";

        var organizationList = [];
        var organizationId = [];
        axios
          .get(`${URL_API}organization/all`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i].company;
              const id = response.data[i].id;
              organizationList.push(element);
              organizationId.push(id);
              this.organizationOptions.push({
                label: organizationList[i],
                value: organizationId[i],
              });
            }
          });
      });
    },
  },
};
</script>

<style></style>
